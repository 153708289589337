<template>
  <div class="dNews-detail-wrap">
    <headNav type="sub" active="4"></headNav>
    <div class="dNews-detail-contents w1200">
      <h2 class="h2">{{detailInfo.title}}</h2>
        <div class="clighter fs18 tal pt36">
          <div>{{templateTime(detailInfo.inputtime)}}</div>
          <div>苏州民族管弦乐团</div>
        </div>

        <div class="dNews-contents">
          <!-- <div class="tal mb40 fs18" v-html="detailInfo.description"></div> -->
          <div v-html="detailInfo.content"></div>
        </div>
    </div>
    <footNav type="sub" />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import moment from  'moment'
export default {
  components: { headNav, footNav },
  data(){
    return {
      id: '',
      detailInfo:{}
    }
  },
  created() {
    this.id = this.$route.params.id || 0

    this.getNewsDetail()
  },
  methods: {
    // 获取演出列表
    getNewsDetail() {
      this.$ajax.get("/cms/api/dnewsdetail", {
        id: this.id
      }).then((res) => {
        if (res.code == "200") {
          // this.showlist = res.data;
          console.log('tag', res)
          this.detailInfo = res
        }
      });
    },

    templateTime(time) {
      if (!time) return
        return moment(time*1000).format('YYYY-MM-DD') 
    },
  },
};
</script>

<style scoped>
.dNews-detail-wrap{
  background-color: #eee;
}

.dNews-detail-contents{
  padding: 200px 0 120px;
}

.dNews-contents{
  background: #f5f5f5;
  box-shadow: 0 0 30px rgba(0,0,0,0.2);
  margin-top: 60px;
  padding:50px;
  border-radius: 10px;
}
</style>
