<template>
  <div class="party-wrap page-width">
    <headNav type="sub" active="4" bg="blank"></headNav>
    <div class="party-title carousel-common">
      <a-carousel arrows dotsClass="home-dots">
        <div
          slot="prevArrow"
          class="custom-slick-arrow"
        >
        left
        </div>
        <div
          slot="nextArrow"
          class="custom-slick-arrow"
        >
          right
        </div>
        <div v-for="(item,key) in topList" :key="key">
          <img class="party-title-img" :src="`${state.baseUrl}${item.thumb}`" alt="" />
        </div>
      </a-carousel>
    </div>
    <div class="party-list">
      <div class="w1200 inner carousel-common party-list-carousel">
        <a-carousel ref="carousel" arrows dotsClass="home-dots">
          <div
          slot="prevArrow"
          class="custom-slick-arrow"
        >
        left
        </div>
        <div
          slot="nextArrow"
          class="custom-slick-arrow"
        >
          right
        </div>
          <div v-for="(item,key) in firstList" :key="key">
            <div class="flex between">
              <div class="item tal pointer" v-for="(sub,index) in item" :key="index" @click="gotoDetail(sub)">
                <div class="pic">
                  <img :src="`${state.baseUrl}${sub.thumb}`" alt="" />
                </div>
                <div class="name fs18 cmain mt20 ellipsis2 bold">
                  {{sub.title}}
                </div>
                <div class="time fs14 clighter mt24">
                  {{templateTime(sub.inputtime)}}
                </div>
                <div class="p fs14 clight mt24 ellipsis3">
                  {{sub.description}}
                </div>
                <span class="more fs12 cmain pointer" >点击阅读更多</span>
              </div>      
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="party-tabs">
      <div class="header flex center" v-if="this.$i18n.locale == 'en'">
        <div class="item flex v-center" v-for="(item,key) in tabListA" :key="key" :class="[key == tabIndex? 'active' : '']" @click="tabAction(key)">
          <img class="pr8" :src="require(`../assets/party/icon${key+1}.png`)" alt="" />
          {{item}}
        </div>
      </div>
      <div class="header flex center" v-else>
        <div class="item flex v-center" v-for="(item,key) in tabList" :key="key" :class="[key == tabIndex? 'active' : '']" @click="tabAction(key)">
          <img class="pr8" :src="require(`../assets/party/icon${key+1}.png`)" alt="" />
          {{item}}
        </div>
      </div>
      <div class="tabs-main">
        <div class="w1200 item flex between pointer" v-for="(item,key) in dNewsList" :key="key" @click="gotoDetail(item)">
          <div class="pic">
            <img :src="`${state.baseUrl}${item.thumb}`" alt="">
          </div>
          <div class="text tal">
            <div class="title fs24 cmain pt4 pb32 ellipsis">
              {{item.title}}
            </div>
            <div class="tips fs16 clight ellipsis3">
              {{item.description}}
            </div>
            <div class="time fs18 clighter pt32">{{templateTime(item.inputtime)}}</div>
          </div>
        </div>
      </div>
    </div>
    <footNav type="sub" />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import moment from  'moment'
const typeList = {
  '0' : 'default' ,
  '1' : 'activity' ,
  '2' : 'release' 
}
export default {
  components: { headNav, footNav },
  data() {
    return {
      tabList: ['党建概况','党群活动','信息发布'],
      tabListA: ['Party Building Overview','Party group activities','Information Release'],
      tabIndex: 0,
      topList: [],
      firstList:[],
      dNewsList: []
    }
  },
  computed: {
    state() {
      return this.$store.state;
    },
  },
  created(){
    
    
    
  },
  mounted(){
    
    this.getFirstList()

    let timer = setTimeout(()=>{
      this.getPartyList()
      this.getTopList()
      clearTimeout(timer)
    },100)
    // console.log('tag', this.$refs['carousel'])
    // this.$nextTick(_=>{
    //   this.getPartyList()
    // })
  },
  methods: {
    tabAction(key) {
      if (key == this.tabIndex) return false;
      this.tabIndex = key
      this.getPartyList()
    },
    getPartyList() {
      //党建新闻类型 top:置顶 default:党建概况 activity:党群活动 release:信息发布
      let type = typeList[this.tabIndex]
      this.$ajax
        .get("/cms/api/dnewslist", {
          dtype : type
        })
        .then((res) => {
          if (res.code == "200") {
            this.dNewsList = res.data
            console.log(this.dNewsList);
          }
        });
    },
    getTopList() {
      this.$ajax
        .get("/cms/api/dnewslist", {
          dtype : 'top'
        })
        .then((res) => {
          if (res.code == "200") {
            this.topList = res.data
            console.log(this.topList);
          }
        });
    },

    // 获取头条
    getFirstList() {
      this.$ajax
        .get("/cms/api/dnewslist", {
          dtype : 'first'
        })
        .then((res) => {
          if (res.code == "200" && res.data.length) {
            let firstList = []
            let data =res.data
            for(let i=0; i<data.length; i+=3){
                firstList.push(data.slice(i,i+3));
            }
            this.firstList = firstList

            console.log('tag', firstList)
            let timer = setTimeout(()=>{
              this.$refs['carousel'].goTo(0)
              clearTimeout(timer)
            },1000)
            
          }
        });
    },
    templateTime(time) {
      if (!time) return
        return moment(time*1000).format('YYYY-MM-DD') 
    },

    gotoDetail(item){
      console.log('22', item)
      this.$router.push({ name: "party-detail", params: { id: item.id } });
    }
  },
};
</script>

<style scoped lang="scss">
.party-wrap {
  background-color: #eee;
}
.party-title {
  height: 806px;
  .party-title-img{
    height: 806px;
    margin: 0 auto;
  }
}

.party-list {
  height: 812px;
  background: url("../assets/party/list-bg.jpg") no-repeat 0 0;
  .inner {
    padding: 110px 50px 0;
  }
  .item {
    width: 360px;
    height: 525px;
    padding: 30px 36px;
    background: #fff url("../assets/party/bg-color.png") no-repeat 45% -15px;
    position: relative;
    // box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.5);
    .pic {
      width: 280px;
      height: 175px;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    .name {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        display: block;
        width: 6px;
        height: 22px;
        background: #b49a71;
        left: -30px;
        top: 2px;
      }
    }
    .more {
      position: absolute;
      left: 36px;
      bottom: 30px;
      text-decoration: underline;
    }
  }
}

.party-tabs {
  .header {
    height: 80px;
    background: #dcdde0;
    .item {
      font-size: 24px;
      padding: 0 65px;
      color: #606266;
      position: relative;
      height: 100%;
      cursor: pointer;
      &.active {
        color: #303032;
        font-weight: bold;
        cursor: inherit;
        &::before {
          position: absolute;
          content: "";
          display: block;
          width: 180px;
          height: 7px;
          background: #303032;
          left: 50%;
          margin-left: -90px;
          bottom: 0px;
        }
      }
    }
  }

  .tabs-main {
    .item {
      height: 265px;
      padding: 32px;
      margin-bottom: 24px;
      border-bottom: 1px solid #919398;
      .pic {
        width: 360px;
        height: 200px;
        overflow: hidden;
        
        img{
          width: 100%;
        }
      }
      .text {
        width: 720px;
      }
    }
  }
}
</style>

<style lang="scss">
.party-list {
  .home-dots.slick-dots {
    width: 1200px;
    text-align: right;
    margin: 0 auto;
    left: 50%;
    margin-left: -600px;
    bottom: -36px;
    height: 10px;
    display: flex;
    padding-right: 50px;
    li {
      width: 10px;
      height: 10px;
      background: #919398;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      border-radius: 10px;
      margin-right: 6px;
      button {
        width: 100%;
        height: 100%;
        opacity: 0;
        background: none;
      }
    }
    .slick-active {
      width: 100px;
      height: 10px;
      box-sizing: border-box;
      background: #ae9e76;
      button {
        width: 100%;
        background: none;
        opacity: 0;
      }
    }
  }
}

.carousel-common{
  .ant-carousel .slick-prev, .ant-carousel .slick-next{
    width: 19px;
    height: 44px;
    z-index: 100;
    margin-top: -60px;
  }

  .slick-prev{
    left: 32px;
    background: url('../assets/left.png');
    &:hover{
      background: url('../assets/left.png');
    }
  }

  .slick-next{
    right: 32px;
    background: url('../assets/right2.png');
    &:hover{
       background: url('../assets/right2.png');
    }
  }
}
.party-list-carousel.carousel-common{
  .slick-prev{
    left: -42px;
  } 
  .slick-next{
    right: -42px;
  }
}
</style>
